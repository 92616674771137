import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/config.json';
import PropTypes from 'prop-types';
import './Login.css';
const endPoint = apiUrl + '/login';

async function loginUser(credentials) {
  return fetch(endPoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function Login({ setToken }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    setToken(token);
  }

  return (
    <div className='login-wrapper'>
      <h1>Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type='text' onChange={e => setUsername(e.target.value)} />
        </label><br />
        <label>
          <p>Password</p>
          <input type='password' onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type='submit'>Submit</button>
        </div>
      </form>
    </div>
  );
}

/* Login.propTypes = {
  setToken: PropTypes.func.isRequired
} */
