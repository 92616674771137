import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/config.json';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { visimisiimg } from '../../assets/img';

const endPoint = apiUrl + '/master_kabupaten';

function VisiMisi(props) {
    return (
        <div>
            <h1 className="subjudul" style={{ fontSize: 30 }}>Visi</h1>
            <p align="center" style={{ fontSize: 20 }}>Terwujudnya Riau yang berdaya saing, sejahtera, bermartabat dan unggul di Indonesia (Riau Bersatu)</p>
            <br />
            <h1 className="subjudul" style={{ fontSize: 30 }}>Misi</h1>
            <div style={{ fontSize: 20 }}>
                <ol>
                    <li>Mewujudkan sumber daya yang beriman, berkualitas dan berdaya saing melalui pembangunan manusia seutuhnya</li>
                    <li>Mewujudkan pembangunan insfrastruktur daerah yang merata dan berwawasan lingkungan</li>
                    <li>Mewujudkan pembangunan ekonomi yang inklusif, mandiri dan berdaya saing</li>
                    <li>Mewujudkan budaya melayu sebagai payung negeri dan mengembangkan pariwisata yang berdaya saing</li>
                    <li>Mewujudkan tata kelola pemerintahan yang baik dan pelayan publik yang prima berbasis teknologi informasi</li>
                </ol>
            </div>
        </div>
    );
}

export default VisiMisi;