import React from 'react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Defenisi" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header="Surat Pergantian Penanggung Jawab Teknis PBF"
        content="Adalah surat yang dikeluarkan oleh Kepala Dinas Kesehatan Provinsi Riau terkait pergantian penanggung jawab PBF yang merupakan bagian yang tidak terpisahkan dari pemenuhan  sertifikat distribusi cabang PBF."
      />
    ),
  },
}))

const Defenisi = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Defenisi;