import React from 'react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Defenisi" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header="Surat Pergantian Apoteker Penanggung Jawab Sementara Pedagang Besar Farmasi (PBF) Cabang"
        content="Adalah surat yang dikeluarkan oleh Kepala Dinas Kesehatan Provinsi Riau terkait pergantian apoteker sementara PBF yang berlaku selama 3 bulan karena APJ tidak bisa memenuhi tanggung jawabnya."
      />
    ),
  },
}))

const Defenisi = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Defenisi;