import React, { Component } from 'react';
import { apiUrl } from '../config/config.json';
import authHeader from '../../Utils/Auth-header';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
const endPoint = apiUrl + '/mst_kabupaten';

export class EditKabModal extends Component {

  constructor(props) {
    super(props);
    this.state = { snackbaropen: false, snackbarmsg: '' };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleSubmit(event) {
    event.preventDefault();
    fetch(endPoint, {
      method: 'PUT',
      headers:
        authHeader(),
      body: JSON.stringify({
        KD_KABUPATEN: event.target.KD_KABUPATEN.value,
        KD_PROVINSI: event.target.KD_PROVINSI.value,
        KABUPATEN: event.target.KABUPATEN.value
      })
    })
      .then(res => res.json())
      .then((result) => {
        //alert(result);
        this.setState({ snackbaropen: true, snackbarmsg: result });
      },
        (error) => {
          //alert('Failed')
          this.setState({ snackbaropen: true, snackbarmsg: 'failed' });
        }
      )
  }


  render() {
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}

          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
</IconButton>
          ]}
        />

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Kabupaten
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <Row>
              <Col sm={6}>
                <Form onSubmit={this.handleSubmit}>


                  <Form.Group controlId="KD_KABUPATEN">
                    <Form.Label>Kode Kabupaten</Form.Label>
                    <Form.Control
                      type="text"
                      name="KD_KABUPATEN"
                      required
                      disabled
                      defaultValue={this.props.KD_KABUPATEN}
                      placeholder="Kode Kabupaten"
                    />
                  </Form.Group>

                  <Form.Group controlId="KD_PROVINSI">
                    <Form.Label>Kode Provinsi</Form.Label>
                    <Form.Control
                      type="text"
                      name="KD_PROVINSI"
                      required
                      defaultValue={this.props.KD_PROVINSI}
                      placeholder="Kode Provinsi"
                    />
                  </Form.Group>

                  <Form.Group controlId="Nama Kabupaten">
                    <Form.Label>Nama Kabupaten</Form.Label>
                    <Form.Control
                      type="text"
                      name="KABUPATEN"
                      required
                      defaultValue={this.props.KABUPATEN}
                      placeholder="Nama Kabupaten"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Button variant="primary" type="submit">
                      Simpan
                  </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>



          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.props.onHide}>Tutup</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}
