import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { pdfUrl } from '../config/config.json';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';

const pdfFile = pdfUrl + 'kodeetik.pdf';

function KodeEtik(props) {


    return (
        <div className="embed-responsive" style={{ height: "100vh" }}>
            <embed
                src={pdfFile}
                type="application/pdf"
                width="100%"
                height="100%"
            />
        </div>
    )
}

export default KodeEtik;