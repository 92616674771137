import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash';
import { Accordion, Label, Message } from 'semantic-ui-react';

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <p>Persyaratan Rekomendasi Izin Operasional Rumah Sakit Kls B meliputi :</p>
            <p>I. Administrasi Manajemen</p>
        	<div style={{ paddingLeft: '60px' }}>
            <List as='ol'>
              <List.Item as='li'>Surat Permohonan Pertimbangan teknis dan rekomendasi dari DPMPTSP tentang izin atau perpanjangan izin Operasional Rumah Sakit Kelas B yang ditujukan kepada Dinas Kesehatan Provinsi Riau.</List.Item>
              <List.Item as='li'>Izin Mendirikan Rumah Sakit, bagi permohonan Izin Operasional</List.Item>
              <List.Item as='li'>Profil RS meliputi visi dan misi, lingkup kegiatan, rencana strategi dan struktur organisasi</List.Item>
              <List.Item as='li'>Isian Instrumen self asesment sesuai klasifikasi rumah sakit yang meliputi pelayanan sumber daya manusia, peralatan, bangunandan prasarana</List.Item>
              <List.Item as='li'>Gambar desain(blue print) dan foto bangunan serta sarana dan prasarana pendukung</List.Item>
              <List.Item as='li'>Izin Penggunaan Bangunan (IPB) dan sertifikat laik Fungsi</List.Item>
              <List.Item as='li'>Dokumen Pengelolaan lingkungan berkelanjutan (dokumen lingkungan UKL-UPL atau AMDAL)</List.Item>
              <List.Item as='li'>Daftar SDM</List.Item>
              <List.Item as='li'>Daftar peralatan medis dan non medis</List.Item>
              <List.Item as='li'>Daftar sediaan farmasi dan Alkes</List.Item>
              <List.Item as='li'>Berita Acara hasil uji Fungsi peralatan Kesehatan di sertai kelengkapan berkas izin pemanfaatan dari instansi berwenang sesuai dengan ketentuan peraturan perundang-undangan untuk peralatan tertentu.</List.Item>
              <List.Item as='li'>Dokumen administrasi dan manajemen
              <div style={{ paddingLeft: '50px' }}>
                <List.List as='ol'>
                  <List.Item as='li'>Badan hukum atau kepemilikan</List.Item>
                  <List.Item as='li'>Peraturan Internal RS (hospital bylaws)</List.Item>
                  <List.Item as='li'>Komite Medik</List.Item>
                  <List.Item as='li'>Komite Keperawatan</List.Item>
                  <List.Item as='li'>Satuan Pemeriksaan Internal</List.Item>
                  <List.Item as='li'>Surat Izin Praktik atau surat izin kerja tenaga</List.Item>
                  <List.Item as='li'>Standar Prosedur Operasional Kredensial Staf Medis</List.Item>
                  <List.Item as='li'>Surat Penugasan Klinis Staf Medis dan Rincian Kewenangan Klinis</List.Item>
                  <List.Item as='li'>Surat Keterangan/Sertifikat Hasil Uji/Kalibrasi Alat Kesehatan</List.Item>
                  <List.Item as='li'>Struktur Organisasi</List.Item>
                  <List.Item as='li'>Tata Laksana/Tata Kerja/Uraian Tugas</List.Item>
                  <List.Item as='li'>Komite etik dan Hukum</List.Item>
                  <List.Item as='li'>Surat Izin Praktik Dokter</List.Item>
                  <List.Item as='li'>Perjanjian Kerjasama RS dan Dokter</List.Item>
                  <List.Item as='li'>Akreditasi RS</List.Item>
                  <List.Item as='li'>Surat Izin Penyelenggaraan Radiologi</List.Item>
                  <List.Item as='li'>Surat Izin Penyelenggaraan Hemodialisa</List.Item>
                </List.List>
				</div>
              </List.Item>
              <List.Item as='li'>Persyaratan Teknis Lainnya
              <div style={{ paddingLeft: '50px' }}>
                <List.List as='ol'>
                  <List.Item as='li'>Nomor Induk Berusaha (NIB)</List.Item>
                  <List.Item as='li'>Izin Medirikan Bangunan (IMB)</List.Item>
                  <List.Item as='li'>Surat Izin Tempat Usaha (SITU)</List.Item>
                  <List.Item as='li'>Izin Lingkungan yang dikeluarkan oleh BLH daerah setempat bagi rumah sakit setelah RS mendapatkan pengesahan dokumen lingkungan (UKL-UPL atau AMDAL)</List.Item>
                  <List.Item as='li'>Izin Peruntukan Perubahan Tanah (SIPPT)</List.Item>
                  <List.Item as='li'>Izin Prinsip Pemanfaatan Tanah</List.Item>
                  <List.Item as='li'>Izin Peruntukan Penggunaan Tanah</List.Item>
                  <List.Item as='li'>Izin Lokasi</List.Item>
                  <List.Item as='li'>Izin Penetapan lokasi</List.Item>
                  <List.Item as='li'>Izin Pemanfaatan Pesawat X-Ray</List.Item>
                  <List.Item as='li'>Izin Operasional Boiler</List.Item>
                  <List.Item as='li'>Izin Operasional Ginset</List.Item>
                  <List.Item as='li'>Izin Lift</List.Item>
                  <List.Item as='li'>Izin Tempat Penyimpanan sementara (TPS) limbah B3</List.Item>
                  <List.Item as='li'>Izin Pembuangan Limbah cair(untuk instalasi pengolahan air limbah)</List.Item>
                  <List.Item as='li'>Izin kegiatan Pengolahan limbah B3 ( bagi fasyankes yang memiliki alat pengolah Limbah B3 anatara lain: autoclaf tipe gravitasi dan atau tipe vakum, gelombang mikro, iradiasi frekuensi radio, incenerator)</List.Item>
                  <List.Item as='li'>Izin Pengambilan air bawah tanah</List.Item>
                  <List.Item as='li'>Izin APAR</List.Item>
                  <List.Item as='li'>Sertifikat kalibrasi Alkes</List.Item>
                  <List.Item as='li'>Sertifikat laik operasi (SLO) listrik</List.Item>
                  <List.Item as='li'>Izin Instalasi Sistem Proteksi Kebakaran</List.Item>
                  <List.Item as='li'>Peil Lantai Bangunan (PLB)/Peil Banjir</List.Item>
                  <List.Item as='li'>Izin Operasional Alat Angkat dan Angkut: Escalator dan Gondola</List.Item>
                  <List.Item as='li'>Izin Operasional Pemanfaatan Penangkal Petir</List.Item>
                </List.List>
				</div>
              </List.Item>
            </List>
		</div>
            <p>II. Pelayanan</p>
            <p>III. Sumber Daya Manusia</p>
            <p>IV. Bangunan dan Prasarana</p>
            <p>V. Peralatan</p>
          </>
        }
      />
    ),
  },
}));

const Persyaratan = () => (
  <Accordion defaultActiveIndex={0} panels={panels} />
);

export default Persyaratan;
