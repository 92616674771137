import React from 'react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Defenisi" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header="Surat Izin Operasional Rumah Sakit Kelas B"
        content="Adalah surat/dokumen yang harus dimiliki oleh RS Kelas B yang akan mengajukan permohonan Ijin Operasional baik yang baru maupun perpanjangan sebagai salah satu syarat untuk memperoleh IJIN OPERASIONAL RS KLS B"
      />
    ),
  },
}))

const Defenisi = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Defenisi;
