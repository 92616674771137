import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
        <div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Surat permintaan data ditujukan kepada Kepala Dinas Kesehatan Provinsi Riau yang ditandatangani oleh kepala/pimpinan institusi. (Khusus permintaan data untuk penelitian mahasiswa adalah surat dari DPMPTSP Kota Pekanbaru</List.Item>
                <List.Item as='li'>Daftar permintaan data</List.Item>
              </List>
              </div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;