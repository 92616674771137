import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <p>Persyaratan Sertifikat Uji Kompetensi Jabatan Fungsional Kesehatan</p>
              Persyaratan Umum
        	<div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Surat Permohonan ditujukan kepada Kepala Dinas Kesehatan Provinsi Riau ( Materai 10000)</List.Item>
                <List.Item as='li'>Surat Pernyataan Bekerja Fulltime dari PJT Pengganti Sementara (Materai 1000)</List.Item>
                <List.Item as='li'>Berita Acara serah Terima dari PJT lama ke PJT Pengganti Sementara yang diketahui oleh Pimpinan Cabang</List.Item>
                <List.Item as='li'>Foto kopi Sertifikat Standar Cabang Distribusi Alat Kesehatan dan NIB</List.Item>
                <List.Item as='li'>Foto kopi Ijazah PJT Sementara</List.Item>
                <List.Item as='li'>Foto kopi KTP PJT Sementara</List.Item>
                <List.Item as='li'>Foto kopi NPWP Perusahaan</List.Item>
              </List>
			</div>
              Persyaratan lainnya
              <div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Screenshot Laporan E-Report Alkes (dari Beranda, data masuk, data keluar dan data partner)</List.Item>
                </List>
            </div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;