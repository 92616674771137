import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, imageUrl } from "../config/config.json";
import authHeader from "../../Utils/Auth-header";
// import { logout, isLogin } from "../../Utils/Index";
import "../../css/TabelCard.css";
const endPoint = apiUrl + "/mst_layanan";

function MstLayananCard() {
  const [MstLayananCard, setMstLayananCard] = useState([]);
  // const [isLogin, setIslogin] = useState();

  useEffect(() => {
    refreshList();
  }, []);

  const refreshList = () => {
    axios
      .get(endPoint
        // {
        // headers: authHeader(),
        // }
      )
      .then((response) => {
        setMstLayananCard(response.data);
      });
  };


  function MouseOver(event) {
    // event.target.style.background = 'green';
  }

  function MouseOut(event) {
    // event.target.style.background = 'url(`${Profilcard.urlgambar}`)';
  }

  return (
    <div className="item-container">
      {MstLayananCard.map((tabel) => (
        <div key={tabel.id} className="item-container"
        >
          <button onMouseOver={MouseOver}
            onMouseOut={MouseOut}
            onClick={() => window.location.href = `${tabel.url} `} className="card"
            style={{
              backgroundImage: `url(${imageUrl + tabel.urlgambar})`
              //  bottom right 15% no - repeat #46B6AC`
            }}
          >

            {/* <div className="card"> */}
            < div className="Tabelcard-title" > {tabel.judul}</div >
            <div className="Tabelcard-data">{tabel.nama_layanan}</div>
            {/* <h3>{tabel.ALIAS}</h3> */}
            {/* <div className="Tabelcard-data">{tabel.url}</div> */}
          </button >
        </div >
      ))}
    </div >
  );
}

export default MstLayananCard;
