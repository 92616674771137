import React from 'react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Defenisi" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header="Surat Keterangan Hasil Penyuluhan PRT ALKES dan/atau PKRT"
        content="Adalah surat keterangan yang diberikan kepada pelaku usaha rumah tangga alat kesehatan dan/atau perbekalan kesehatan rumah tangga (PKRT) yang telah mendapatkan penyuluhan dari petugas kesehatan provinsi dan telah melengkapi berkas yang sesuai dengan PERMENKES Nomor 70 Tahun 2014. Surat keterangan penyuluhan alat kesehatan dan perbekalan kesehatan rumah tangga (PKRT) merupakan salah satu syarat untuk mengajukan permohonan sertifikat perusahaan rumah tangga alat kesehatan dan/atau perbekalan kesehatan rumah tangga (PKRT)."
      />
    ),
  },
}))

const Defenisi = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Defenisi;
