import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Waktu" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
        <>
        <div style={{ paddingLeft: '40px' }}>
          <List as='ol'>
          <List.Item as='li'>Menelaah berkas :  1 hari</List.Item>
          <List.Item as='li'>Surat Pertimbangan Teknis : 5 hari</List.Item>
         <List.Item as='li'>Mengirim Surat Pertimbangan Teknis ke sistem SIMPEL/Online DPMPTSP : 1 hari</List.Item>
         </List>
          </div>
        </>
        }
      />
    ),
  },
}))

const Waktu = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Waktu;
