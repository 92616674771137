import React from 'react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Waktu" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content="3 (tiga) hari atau tentatif sesuai dengan kondisi."
      />
    ),
  },
}))

const Waktu = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Waktu;
