import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/config.json';
import authHeader from '../../Utils/Auth-header';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { AddKabModal } from './AddKabModal';
import { EditKabModal } from './EditKabModal';
const endPoint = apiUrl + '/master_kabupaten';

function Kabupaten(props) {

    const [kabs, setKabs] = useState([])
    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [KD_KABUPATEN, setKd_kabupaten] = useState('');
    const [KD_PROVINSI, setKd_Provinsi] = useState('');
    const [KABUPATEN, setKabupaten] = useState('');

    let AddModalClose = () => {
        addModalShow = useState(false)
    }

    let EditModalClose = () => {
        editModalShow = useState(false)
    }

    useEffect(() => {
        refreshList()
    }, [])

    const refreshList = () => {
        axios.get(endPoint, /* {
            headers:
                authHeader()
            } */)
            .then(response => {
                setKabs(response.data)
            });
    }

    const deleteKab = (KD_KABUPATEN) => {
        if (window.confirm('Are you sure?')) {
            axios.delete(endPoint + '/' + KD_KABUPATEN, {
                headers: {
                    authHeader
                },
                data: {
                    KD_KABUPATEN: KD_KABUPATEN
                }
            })
        }
    }

    /*  render() {
     
         const { kabs, KD_KABUPATEN, KD_PROVINSI, KABUPATEN } = this.state;
         let addModalClose = () => this.setState({ addModalShow: false });
         let editModalClose = () => this.setState({ editModalShow: false });
    */
    return (
        <div>
            <Table className="mt-4" striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Kode Kabupaten</th>
                        <th>Kode Provinsi</th>
                        <th>Nama Kabupaten</th>
                        <th>Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    {kabs.map(kab =>
                        <tr key={kab.KD_KABUPATEN}>
                            <td>{kab.KD_KABUPATEN}</td>
                            <td>{kab.KD_PROVINSI}</td>
                            <td>{kab.KABUPATEN}</td>
                            <td>
                                <ButtonToolbar>
                                    <Button
                                        disabled={true} className="mr-2" variant="info"
                                        onClick={() => setEditModalShow(true)}
                                    //this.setState({ editModalShow: true, KD_KABUPATEN: kab.KD_KABUPATEN, KD_PROVINSI: kab.KD_PROVINSI, KABUPATEN: kab.KABUPATEN })}
                                    >
                                        Edit
                                    </Button>

                                    <Button className="mr-2"
                                        onClick={() => deleteKab()}
                                        variant="danger"
                                    >Delete</Button>

                                    <EditKabModal
                                        show={editModalShow}
                                        onHide={EditModalClose}
                                        setKd_Kabupaten={KD_KABUPATEN}
                                        setKd_Provinsi={KD_PROVINSI}
                                        setKabupaten={KABUPATEN}
                                    />

                                </ButtonToolbar>

                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <ButtonToolbar>
                <Button
                    disabled={true} variant='primary'
                    onClick={() => setAddModalShow = (useState = true)}
                >Tambah Kabupaten</Button>

                <AddKabModal
                    show={addModalShow}
                    onHide={AddModalClose}
                />

            </ButtonToolbar>
        </div>
    )
}

export default Kabupaten;