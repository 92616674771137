import React from 'react';
import '../../App.css';
import { alurrskelasb } from '../../assets/img';
import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Alur Pengajuan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={<img src={alurrskelasb} alt="Alur Rumah Sakit Kelas B" style={{ width: "80%", height: "80%" }} />}
      />
    ),
  },
}))

const Alur = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Alur;
