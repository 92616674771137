import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavBar = ({ user }) => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <Link className='navbar-brand' to='/'>
        e-Senyap
      </Link>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNavAltMarkup'
        aria-controls='navbarNavAltMarkup'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon' />
      </button>
      <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
        <div className='navbar-nav'>
          <NavLink className='nav-item nav-link' to='/mst_layanan'>
            Home
          </NavLink>
          <NavLink className='nav-item nav-link' to='/visimisi'>
            Visi Misi
          </NavLink>
          <NavLink className='nav-item nav-link' to='/maklumat'>
            Maklumat
          </NavLink>
          <NavLink className='nav-item nav-link' to='/kodeetik'>
            Kode Etik
          </NavLink>
          <NavLink className='nav-item nav-link' to='/standar'>
            Standar Pelayanan
          </NavLink>
          <NavLink className='nav-item nav-link' to='/motto'>
            Motto Pelayanan
          </NavLink>
          <NavLink className='nav-item nav-link' to='/timpel'>
            Tim Pelayanan
          </NavLink>
          <NavLink className='nav-item nav-link' to='/alurpel'>
            Alur Pelayanan
          </NavLink>
          <NavLink className='nav-item nav-link' to='/pengaduan'>
            Tim Pengaduan
          </NavLink>
          <NavLink className='nav-item nav-link' to='/kompensasi'>
            Kompensasi
          </NavLink>
          {user && (
            <>
              <NavLink className='nav-item nav-link' to='/profile'>
                {user.nama}
              </NavLink>
              <NavLink className='nav-item nav-link' to='/logout'>
                Logout
              </NavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
