import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import ProtectedRoute from './components/common/protectedRoute';
import auth from './services/authService';
import 'react-toastify/dist/ReactToastify.css';
//import NavBar from './components/Navbar';
import Routes from './Utils/Routes';
import useToken from './Utils/useToken';
import Login from './components/Login/Login';
import { logout, isLogin } from "./Utils/Index";


import './App.css';

function App() {
  const [isLogin, setIslogin] = useState();

  function handleLogout() {
    logout();
    setIslogin = false;
  }
  return <Routes />
  // const { token, setToken } = useToken();

  // if (!token) {
  //   return <Login setToken={setToken} />;
  // }

  //return <Routes setToken={setToken} />;
  // return <Routes />
}

export default App;
