import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <p>Persyaratan Pergantian Apoteker Penangung Jawab Sementara PBF yaitu:</p>
        	<div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Surat Permohonan ditujukan kepada Kepala Dinas Kesehatan Provinsi Riau yang ditandatangani oleh Pimpinan Perusahaan (Materai Rp. 10.000,- );</List.Item>
                <List.Item as='li'>Melampirkan Perjanjian bekerja penuh (Full Time) dari Apoteker Penanggung jawab (Materai 10.000);</List.Item>
                <List.Item as='li'>Melampirkan Berita acara serah terima dari Apoteker penanggungjawab lama ke Apoteker penanggung jawab sementara yang diketahui oleh Pimpinan Perusahaan;</List.Item>
                <List.Item as='li'>Melampirkan Fotocopy ijazah Apoteker Penangung Jawab sementara;</List.Item>
                <List.Item as='li'>Melampirkan Fotocopy Surat Tanda Registrasi Apoteker (STRA) penanggung Jawab sementara;</List.Item>
                <List.Item as='li'>Melampirkan Fotocopy Sertifikat Kompetensi Apoteker Penanggung Jawab sementara;</List.Item>
                <List.Item as='li'>Melampirkan Fotocopy Izin PBF Cabang;</List.Item>
                <List.Item as='li'>Melampirkan Fotocopy KTP Apoteker Penanggung Jawab Sementara;</List.Item>
                <List.Item as='li'>Melampirkan bukti print out Nomor Induk Berusaha (NIB)’</List.Item>
              </List>
			</div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;