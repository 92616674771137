import React, { useEffect, useState } from "react";
import token from '../Utils/useToken';

export const logout = () => {
  localStorage.removeItem(token);
}

export const isLogin = () => {
  if (localStorage.getItem(token)) {
    return true;
  }

  return false;
}