import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/config.json';
import { pdfUrl } from '../config/config.json';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { alursemua } from '../../assets/img';

const pdfFile = pdfUrl + 'alur_pelayanan.pdf';

function AlurPelayanan(props) {

    return (
        <div className="embed-responsive" style={{ height: "100vh" }}>
            <embed
                src={pdfFile}
                type="application/pdf"
                width="100%"
                height="100%"
            />
        </div>
    )
}

export default AlurPelayanan;