import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <p>Persyaratan Rekomendasi Registrasi Puskesmas sesuai Permenkes No. 43 Tahun 2019 Tentang Puskesmas</p>
              PERSYARATAN DOKUMEN
        	<div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Surat Permohonan registrasi Puskesmas</List.Item>
                <List.Item as='li'>Foto Copy SK Izin Operasional Puskesmas yg di keluarkan oleh kepala daerah atau bagian Perizinan</List.Item>
                <List.Item as='li'>Foto Copy SK Kategori Puskesmas</List.Item>
                <List.Item as='li'>Profil Puskesmas</List.Item>
                <List.Item as='li'>Laporan kegiatan bulanan 3 (tiga) bulan terakhir</List.Item>
              </List>
			</div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;