import alurpak from './1-pak.png';
import alurskscasn from './2-skscasn.png';
import alurregpus from './3-regpus.png';
import alursttpenyehattra from './4-sttpenyehattra.png';
import alurrskelasb from './5-rskelasb.png';
import alurlabrtpcr from './6-labrtpcr.png';
import alurpakpkrt from './7-pakpkrt.png';
import alurukot from './8-ukot.png';
import alurcabangpak from './9-cabangpak.png';
import alurhibah from './10-hibah.png';
import alursrttk from './11-srttk.png';
import alursurtugdrdrg from './12-surtugdrdrg.png';
import alurgantipimpinanpbf from './13-gantipimpinanpbf.png';
import alurtambahgudangpbf from './14-tambahgudangpbf.png';
import alurapotekersementara from './15-apotekersementara.png';
import alurpjtpbf from './16-pjtpbf.png';
import alurteknissertifikasipbf from './17-teknissertifikasipbf.png';
import alurjamkesda from './18-jamkesda.png';
import alurpermintaandata from './20.png';
import alursertifikat from './19-sertifikatalkes.png';
import alursemua from './alur.jpeg';
import maklumat from './MaklumatPelayanan.png';
import visimisiimg from './visimisiimg.png';

export {
  alurpak,
  alurskscasn,
  alurregpus,
  alursttpenyehattra,
  alurrskelasb,
  alurlabrtpcr,
  alurpakpkrt,
  alurukot,
  alurcabangpak,
  alurhibah,
  alursrttk,
  alursurtugdrdrg,
  alurpermintaandata,
  alurgantipimpinanpbf,
  alurtambahgudangpbf,
  alurapotekersementara,
  alurpjtpbf,
  alurteknissertifikasipbf,
  alursertifikat,
  alurjamkesda,
  alursemua,
  visimisiimg,
  maklumat
};