import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/config.json';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import '../../App.css';

const endPoint = apiUrl + '/master_kabupaten';

function MottoPelayanan(props) {

    return (
        <div align="center">
            <h1>MOTTO PELAYANAN</h1>
            <p className="subjudul" style={{ fontSize: 50 }}>S E H A T</p>
            <p>(Senyum, Efektif & Efisien, Humanis, Akuntabel, Transparan)</p>
            <p className="subjudul" style={{ fontSize: 50 }}>Untuk Semua</p>
        </div>
    )
}

export default MottoPelayanan;