import React, { useEffect, useState } from 'react';
//import { pdfjs, Document, Page } from 'react-pdf';
import axios from 'axios';
import { pdfUrl } from '../config/config.json';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';

const pdfFile = pdfUrl + 'sk_tim_yanlik.pdf';

function TimPelayanan(props) {
    // const pdfFile = '../../assets/pdf/sk_tim_yanlik_pdf'

    return (
        <div className="embed-responsive" style={{ height: "100vh" }}>
            <embed
                //src={url = { pdfFile }}
                src={pdfFile}
                type="application/pdf"
                width="100%"
                height="100%"
            />
        </div>
    )
}

export default TimPelayanan;