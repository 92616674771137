import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <p>Persyaratan Pergantian Apoteker Penangung Jawab Sementara PBF yaitu:</p>
        	<div style={{ paddingLeft: '50px' }}>
              <List as='ol'>
                <List.Item as='li'>Surat Permohonan ditujukan ke Kepala Dinas Penanaman Modal dan Pelayanan Perizinan Satu Pintu Provinsi Riau</List.Item>
                <List.Item as='li'>Fotocopy KTP/ Identitas Direktur/ Pimpinan Perusahaan</List.Item>
                <List.Item as='li'>Daftar Susunan Direksi dan Komisaris</List.Item>
                <List.Item as='li'>Pernyataan Komisaris/dewan pengawas dan Direktur/pengurus tidak pernah terlibat, baik langsung atau tidak langsung dalam pelanggaran peraturan perudang - undangan di bidang farmasi dalam kurun waktu 2 (dua) tahun terakhir (Materai)</List.Item>
                <List.Item as='li'>Fotocopy Surat Perjanjian Kerjasama antara Direksi dengan Apoteker Penanggung Jawab yang disahkan Notaris</List.Item>
                <List.Item as='li'>Perjanjian bekerja penuh (Full Time) dari Apoteker Penanggung jawab (Materai)</List.Item>
                <List.Item as='li'>Surat Pengunduran diri Apoteker yang lama sebagai penanggung jawab</List.Item>
                <List.Item as='li'>Berita acara serah terima dari Apoteker penanggungjawab lama ke Apoteker penanggung jawab baru</List.Item>
                <List.Item as='li'>Fotocopy ijazah Apoteker Penangung Jawab</List.Item>
                <List.Item as='li'>Fotocopy Surat tanda Registrasi Apoteker penanggung Jawab</List.Item>
                <List.Item as='li'>Fotocopy Izin/ Sertifikat Distribusi Cabang PBF sebelumnya</List.Item>
                <List.Item as='li'>Fotocopy SIPA Apoteker lama</List.Item>
                <List.Item as='li'>Fotocopy Sertifikat Kompetensi Apoteker Penanggung Jawab</List.Item>
                <List.Item as='li'>Fotocopy KTP Apoteker Penanggung Jawab Lama</List.Item>
                <List.Item as='li'>Fotocopy KTP Apoteker Penangung Jawab Baru</List.Item>
                <List.Item as='li'>Fotocopy NPWP Perusahaan</List.Item>
                <List.Item as='li'>Nomor Induk Berusaha (NIB) dari sistem OSS</List.Item>
                <List.Item as='li'>Izin Usaha (Surat Izin Usaha Perdagangan) dari sistem OSS</List.Item>
                <List.Item as='li'>Izin Lokasi dari sistem OSS</List.Item>
                <List.Item as='li'>Izin Komersial/Operasional dari sistem OSS</List.Item>
              </List>
            </div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;