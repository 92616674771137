import React from 'react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Defenisi" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header="Surat Jaminan Kesehatan Daerah"
        content="Adalah bukti persetujuan yang harus dimiliki pemohon sebagai persyaratan bagi masyarakat miskin dan tidak mampu untuk mendapatkan pembiayaan pelayanan kesehatan di PPK II yang bekerja sama dengan Dinas Kesehatan Provinsi Riau."
      />
    ),
  },
}))

const Defenisi = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Defenisi;
