import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import MstLayananCard from '../components/MstLayanan/MstLayanan';
import VisiMisi from '../components/VisiMisi/VisiMisi';
import LayRTPCR from '../components/01';
import LayIzinRS from '../components/02';
import LayKetSehat from '../components/03';
import PengaduanBPRS from '../components/04';
import LayRegPus from '../components/05';
import LayApoteker from '../components/06';
import LaySurtugDok from '../components/07';
import LayIzinPBF from '../components/08';
import LayJamkesda from '../components/09';
import LayAlkes from '../components/10';
import LayUkom from '../components/11';
import LaySertifikat from '../components/12';
import LayPermintaanData from '../components/13';
import Maklumat from '../components/MaklumatPelayanan/MaklumatPelayanan';
import KodeEtik from '../components/KodeEtik/KodeEtik';
import StandarPelayanan from '../components/StandarPelayanan/StandarPelayanan';
import MottoPelayanan from '../components/MottoPelayanan/MottoPelayanan';
import TimPelayanan from '../components/TimPelayanan/TimPelayanan';
import AlurPelayanan from '../components/AlurPelayanan/AlurPelayanan';
import TimPengaduan from '../components/TimPengaduan/TimPengaduan';
import Kompensasi from '../components/Kompensasi/Kompensasi';
import Kabupaten from '../components/Kabupaten/Kabupaten'
// import Kecamatan from '../components/Kecamatan/Kecamatan'
// import Kelurahan from '../components/Kelurahan/Kelurahan'
import NotFound from '../components/Login/notfound';
import NavBar from '../components/Navbar';
import Login from '../components/Login/Login';
import Logout from '../components/Login/Logout';

const Routes = ({ setToken }) => {

  return (
    <>
      {/* <ToastContainer />
      <NavBar user={user} /> */}
      <NavBar />
      <main className="container">
        <Switch>
          {/* <Route path='/' component={Home} exact /> */}
          <Route path='/login' component={Login}>
            <Login setToken={setToken} />
          </Route>
          <Route path='/logout' component={Logout} />
          <Route path='/mst_layanan' component={MstLayananCard} />
          <Route path='/visimisi' component={VisiMisi} />
          <Route path='/rtpcr' component={LayRTPCR} />
          <Route path='/izinrs' component={LayIzinRS} />
          <Route path='/skersehat' component={LayKetSehat} />
          <Route path='/bprs' component={PengaduanBPRS} />
          <Route path='/regpus' component={LayRegPus} />
          <Route path='/apoteker' component={LayApoteker} />
          <Route path='/dr_drsp' component={LaySurtugDok} />
          <Route path='/pbf' component={LayIzinPBF} />
          <Route path='/jamkesda' component={LayJamkesda} />
          <Route path='/alkes' component={LayAlkes} />
          <Route path='/jafungkes' component={LayUkom} />
          <Route path='/sertifikatalkes' component={LaySertifikat}/>
          <Route path='/permintaandata' component={LayPermintaanData}/>
          <Route path='/maklumat' component={Maklumat} />
          <Route path='/kodeetik' component={KodeEtik} />
          <Route path='/standar' component={StandarPelayanan} />
          <Route path='/motto' component={MottoPelayanan} />
          <Route path='/timpel' component={TimPelayanan} />
          <Route path='/alurpel' component={AlurPelayanan} />
          <Route path="/pengaduan" component={TimPengaduan} />
          <Route path="/kompensasi" component={Kompensasi} />

          <Route path='/kabupaten' component={Kabupaten} />
          {/*  <Route path='/puskesmas' component={Puskesmas} />
          <Route path='/kecamatan' component={Kecamatan} /> */}
          {/* <Route path='/kelurahan' component={Kelurahan} /> */}

          {/*  //     <Route path='/user' component={User}/>  */}
          <Route path='/not-found' component={NotFound} />
          <Redirect from='/' exact to='/mst_layanan' />
          <Redirect to='/not-found' />

        </Switch>
      </main>
    </>
  );
};

export default Routes;