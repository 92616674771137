import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
        <p>Persyaratan Sertifikat Uji Kompetensi Jabatan Fungsional Kesehatan</p>
              <div style={{ paddingLeft: '45px' }}>
              <List as='ol'>
                <List.Item as='li'>Melakukan registrasi dan login peserta melalui Aplikasi E-UKOM KEMENKES dengan mengupload Surat rekomendasi dari instansi yang ditandatangani oleh Kepala Dinas Kesehatan/Direktur masing-masing Instansi, SK pangkat terakhir, SKP 1 Tahun terakhir dan Foto berlatar belakang merah dengan format PNG;</List.Item>
                <List.Item as='li'>SK Jenjang Jabatan Fungsional Terakhir;</List.Item>
                <List.Item as='li'>PAK Jabatan Fungsional terakhir;</List.Item>
                <List.Item as='li'>Surat Izin pimpinan untuk ikut Uji Kompetensi;</List.Item>
                <List.Item as='li'>SKP selama satu (1) tahun terakhir, membawa bukti yang lama;</List.Item>
                <List.Item as='li'>Logbook 2 (dua) tahun terakhir, membawa bukti yang lama;</List.Item>
                <List.Item as='li'>Surat Pernyataan Kesediaan untuk mengikuti Uji Kompetensi;</List.Item>
                <List.Item as='li'>Fotocopy Ijazah Terakhir, legalisir;</List.Item>
                <List.Item as='li'>Fotocopy Sertifikat Pelatihan;</List.Item>
                <List.Item as='li'>Bukti Pekerjaan yang terkait dengan Jabatan yang akan diduduki minimal 2 tahun terakhir;</List.Item>
                <List.Item as='li'>STR Aktif.</List.Item>
              </List>
			</div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;