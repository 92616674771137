import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
        <div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Administrasi Umum</List.Item>
				<div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Berbadan Hukum berupa Perseroan Terbatas (PT) atau Koperasi;</List.Item>
                    <List.Item as='li'>Surat permohonan</List.Item>
                    <List.Item as='li'>Surat Komitmen Penerapan Prinsip CDKAB</List.Item>
                    <List.Item as='li'>Izin Penyalur/ Sertifikat Distribusi Alat Kesehatan Lama (Perubahan dan atau Perpanjangan)</List.Item>
                    <List.Item as='li'>Laporan Distribusi Alat Kesehatan (e-report alkes)</List.Item>
                    <List.Item as='li'>FC Akta Perusahaan yang menunjukkan bahwa Perusahaan tersebut PMA;</List.Item>
                    <List.Item as='li'>Foto kopi NPWP Perusahaan</List.Item>
                  </List.Item>
				</div>
                <List.Item as='li'>Bangunan dan Prasarana</List.Item>
				<div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Denah/ Layout bangunan;</List.Item>
                    <List.Item as='li'>Status Bangunan ( Milik sendiri/sewa);</List.Item>
                    <List.Item as='li'>Rencana Operasional Usaha</List.Item>
                    <List.Item as='li'>Surat Pernyataan Rencana Operasional Usaha</List.Item>
                    <List.Item as='li'>Lampirkan SOP Penerimaan Produk, SOP Penyimpanan Produk Alkes, SOP Distribusi Produk Alkes;</List.Item>
                    <List.Item as='li'>Lampirkan Surat Jaminan Purna Jual ( Khusus sarana yang mendistribusikan Alkes Elektromedik dan Intrumen DIV).</List.Item>
                  </List.Item>
				</div>
                <List.Item as='li'>Peralatan</List.Item>
				<div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Daftar perlengkapan di gudang penyimpangan alkes</List.Item>
                  </List.Item>
				</div>
                <List.Item as='li'>SDM </List.Item>
				<div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Penanggungjawab Teknis
                    <div style={{ paddingLeft: '30px' }}>
                      <List.Item as='ul'>
                          <List.Item as='li'>Memiliki 1 (satu) orang PJT berkewarganegaraan Indonesia; </List.Item>
                          <List.Item as='li'>Tidak  merangkap jabatan sebagai direksi/komisaris</List.Item>
                          <List.Item as='li'>Melampirkan scan KTP asli;</List.Item>
                          <List.Item as='li'>Apabila berbeda lokasi/ tidak terjangkau dengan perusahaan, agar melampirkan surat keterangan domisili di daerah yang terjangkau untuk pulang-pergi ke sarana distribusi setiap hari;</List.Item>
                          <List.Item as='li'>Melampirkan scan ijazah asli dengan pendidikan sesuai dengan produk yang distribusikan (lihat Permenkes No. 14 Tahun 2021), Jika sarana distribusi mendistribusikan 4 (empat) atau 5 (lima) kelompok alkes, maka  PJT harus berpendidikan minimal S1 dengan pendidikan yang sesuai (lihat Permenkes No. 14 Tahun 2021) dan sertifikat Pelatihan CDAKB;</List.Item>
                          <List.Item as='li'>Surat penyataan bekerja full time sesuai template (dibuat paling lama 3 bulan sebelum pengajuan berkas);</List.Item>
                          <List.Item as='li'>Melampirkan surat kerjasama Perusahaan dengan PJT, bukan penunjukan (Telah dilegalisir oleh Notaris);</List.Item>
                          <List.Item as='li'>Melampirkan struktur organisasi perusahaan yang tercantum jelas posisi Pimpinan dan Penanggung Jawab Teknis;</List.Item>
                          <List.Item as='li'>Memiliki uraian tugas untuk semua posisi sesuai dengan struktur organisasi minimal Pimpinan dan Penanggung Jawab Teknis.</List.Item>
                      </List.Item>
						</div>
                    </List.Item>
                    <List.Item as='li'>Teknisi (Khusus sarana distribusi yang mendistribusikan alkes elektromedik dan instrument produk diagnostik in vitro)
                    <div style={{ paddingLeft: '30px' }}>  
					<List.Item as='ul'>
                          <List.Item as='li'>Jika Bengkel Bekerja sama dengan distributor atau produsen pemilik izin edar yang didistribusikan harus melampirkan data teknisi Kerjasama</List.Item>
                          <List.Item as='li'>Melampirkan scan KTP asli Teknisi</List.Item>
                          <List.Item as='li'>Melampirkan scan ijazah asli ( pendidikan minimal SMK bidang teknik elektro, mesin atau otomotif);</List.Item>
                          <List.Item as='li'>Surat Pernyataan bekerja Fulltime (dibuat paling lama 3 bulan sebelum pengajuan berkas).</List.Item>
                      </List.Item>
					</div>
                    </List.Item>
                    <List.Item as='li'>Petugas Proteksi Radiasi
                    <div style={{ paddingLeft: '30px' }}>
                    <List.Item as='ul'>
                          <List.Item as='li'>Khusus sarana yang mendistribusikan alkes elektromedik radiasi;</List.Item>
                          <List.Item as='li'>Jika bekerja sama dengan importir atau produsen pemilik izin edar yang didistribusikan harus melampirkan surat kerjasama san data PPR;</List.Item>
                          <List.Item as='li'>Melampirkan scan asli KTP Petugas Proteksi Radiasi (PPR) , Surat Izin Bekerja (SIB) PPR Medik Tingkat 1 yang masih berlaku;</List.Item>
                          <List.Item as='li'>Melampirkan scan asli Sertifikat Pelatihan PPR dari BAPETEN;</List.Item>
                          <List.Item as='li'>Melampirkan scan asli izin Pemanfaatan Tenaga Nuklir dari BAPETEN atas nama Perusahaan.</List.Item>
                      </List.Item>
					</div>
                    </List.Item>
                  </List.Item>
				</div>
                <List.Item as='li'>Izin Distribusi Alat Kesehatan Pusat</List.Item>
				<div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Lampirkan FC Izin Distribusi Alat Kesehatan Pusat</List.Item>
                    <List.Item as='li'>Lampirkan sertifikat CDAKB Pusat</List.Item>
                    <List.Item as='li'>Lampirkan sertifikat CDAKB Cabang yang terbit sebelumnya jika ada</List.Item>
                  </List.Item>
				</div>
                <List.Item as='li'>Penunjukan dari Ditributor Alat Kesehatan Pusat
                <div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Surat Penunjukan dari Distributor Alat Kesehatan Pusat.</List.Item>
                  </List.Item>
				</div>
                </List.Item>
                <List.Item as='li'>Retribusi
				<div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Lampirkan Bukti Pembayaran Retribusi ( Bila belum ada ketentuan maka lampirkan surat pernyataan bahwa tidak terdapat retribusi dari Pemerintah Daerah Setempat perihal pengurusan Rekomendasi Sertifikat Standar Cabang Distribusi Alat Kesehatan PMA).</List.Item>
                  </List.Item>
				</div>
                </List.Item>
                <List.Item as='li'>Persyaratan Izin Lainnya
                <div style={{ paddingLeft: '30px' }}>
                  <List.Item as='ul'>
                    <List.Item as='li'>Lampirkan Daftar Jenis Alkes yang disalurkan yang mencantumkan jenis produk pada data isian sesuai dengan kelompok alkes yang disalurkan (Pastikan kelompok alkes yang didistribusikan termasuk kelompok alkes yang dapat di cek pada http://infoalkes.kemkes.go.id/. Lampirkan juga Brosur Produk Alkes yang akan disalurkan.</List.Item>
                  </List.Item>
                  </div>
                </List.Item>
              </List>
              </div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;