import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/config.json';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { maklumat } from '../../assets/img';


function MaklumatPelayanan(props) {


    return (
        <div>
            <p align="center" style={{ fontSize: 30 }}>
                <img src={maklumat}
                    alt="Maklumat Pelayanan" style={{
                        height: "80%", width: "80%"
                    }}
                />
            </p>

        </div >

    )
}

export default MaklumatPelayanan;