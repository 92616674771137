import React from 'react';
import NavBar from '../Navbar';
import Defenisi from './Defenisi'
import Alur from './Alur';
import Persyaratan from './Persyaratan';
import Waktu from './Waktu';
import Tarif from './Tarif';

const PengaduanBPRS = () => {
  return (
    <nav><NavBar />
      <div>
        <Defenisi />
        <Alur />
        <Persyaratan />
        <Waktu />
        <Tarif />
      </div>
    </nav>
  )
}

export default PengaduanBPRS;