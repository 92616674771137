import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <div style={{ paddingLeft: '45px' }}>
              <List as='ol'>
                <List.Item as='li'>Surat Permohonan Jaminan Pelayanan Kesehatan melalui Jamkesda Provinsi Riau yang dikeluarkan oleh Dinas Kesehatan Kabupaten/Kota Se-Provinsi Riau (Asli);</List.Item>
                <List.Item as='li'>Bukti Telah didaftarkan sebagai Peserta PBI/JK yang dikeluarkan oleh Dinas Kesehatan / Dinas Sosial Kabupaten /Kota Provinsi Riau dan ditandatangani oleh Pejabat Berwenang Minimal Eselon IV (Nama, Jabatan, NIP dan di Cap Basah);</List.Item>
                <List.Item as='li'>Surat Keterangan dari Dinas Sosial Kabupaten/Kota atau OPD yang membidangi Masalah Kesejahteraan dan Pembiayaan Sosial bagi Peserta Penyandang Masalah Kesejahteraan Sosial (PMKS) (Asli);</List.Item>
                <List.Item as='li'>Kartu Tanda Penduduk (KTP) Pasien (Photocopy);</List.Item>
                <List.Item as='li'>Kartu Keluarga (KK) Pasien (Photocopy);</List.Item>
                <List.Item as='li'>Melampirkan Foto Rumah Tempat Tinggal (Tampak depan, samping kanan-kiri, Tampak dalam rumah dan kamar mandi) (dicap Basah dan di Tandatangani oleh RT/RW setempat);</List.Item>
                <List.Item as='li'>Melampirkan Foto Pasien yang akan dirujuk ke Rumah Sakit atau foto pasien yang sedang Rawat Inap di Rumah Sakit;</List.Item>
                <List.Item as='li'>Surat Rujukan dari Pemberi Pelayanan Kesehatan (PPK) atau Surat Keterangan Dirawat dari Rumah Sakit jika pasien melalui Unit Gawat Darurat (UGD) (Photocopy);</List.Item>
                <List.Item as='li'>Pengajuan Berkas Administrasi JAMKESDA Provinsi Riau :
					<div style={{ paddingLeft: '40px' }}>
                  <List.List as='ol'>
                    <List.Item as='li'>Kota Pekanbaru : 2 x 24 Jam Sejak Tanggal Rawat Inap dihitung pada hari kerja;</List.Item>
                    <List.Item as='li'>Kabupaten/Kota diluar Pekanbaru : 3 x 24 Jam Sejak Tanggal Rawat Inap dihitung pada hari kerja.</List.Item>
                  </List.List>
					</div>
                </List.Item>
                <List.Item as='li'>Kartu Tanda Penduduk (KTP) Kedua Orang Tua dan Akta Kelahiran Jika Pasien Berusia dibawah 17 (Tujuh Belas) Tahun (Photocopy);</List.Item>
                <List.Item as='li'>Menandatangani Surat Pernyataan Pertanggungjawaban Mutlak (Asli);</List.Item>
                <List.Item as='li'>Bagi permohonan Jampersal, melampirkan Surat Keterangan dari Dinas Kesehatan Kabupaten/Kota Provinsi Riau, yang menyatakan bahwa Dana Jampersal tidak tersedia (Asli).</List.Item>
              </List>
            </div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;