import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            {/*<h1 className='persyaratan'>PERSYARATAN</h1>*/}
            <p>Surat Permohonan ditujukan kepada Kepala Dinas Kesehatan Provinsi Riau yang ditandatangani oleh Pemohon/Pelaku Usaha diatas materai (sesuai format yang telah ditetapkan) dan ditembuskan kepada Kepala Dinas Kesehatan Kab/Kota.
            </p>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;