import React from 'react';
import { List } from 'semantic-ui-react';
import '../../App.css';

import _ from 'lodash'
import { Accordion, Label, Message } from 'semantic-ui-react'

const panels = _.times(1, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Label color='blue' className='subjudul' style={{ fontSize: '30px' }} content="Persyaratan" />,
  },
  content: {
    content: (
      <Message
        style={{ fontSize: '30px' }}
        info
        header=""
        content={
          <>
            <div style={{ paddingLeft: '40px' }}>
              <List as='ol'>
                <List.Item as='li'>Instruksi Presiden Nomor 4 Tahun 2013 tentang Program Dekade Aksi Keselamatan Jalan, (Lembaran Negara Republik Indonesia Nomor 5587);</List.Item>
                <List.Item as='li'>Keputusan Menteri Kesehatan RI Nomor 301 Tahun 2012 tentang Tim Pengembangan Safe Community dan SPGDT</List.Item>
              </List>
            </div>
          </>
        }
      />
    ),
  },
}))

const Persyaratan = () => (
  <Accordion defaultActiveIndex={1} panels={panels} />
)

export default Persyaratan;