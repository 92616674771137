export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('token'));
  const token = user.token;
  //tambah di bawah ini
  if (user && token) {
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    //return { token };

  } else {
    return {};
  }

}